import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import LabSampleStore from 'fabscale-app/services/store/lab-sample';
import { PaginatedLabSamples } from 'fabscale-app/models/lab-sample';
import { restartableTask } from 'ember-concurrency';
import RouterService from '@ember/routing/router-service';
import { deserializeDateRange } from 'fabscale-app/utilities/utils/serialize-date-range';
import { deserializeArray } from 'fabscale-app/utilities/utils/serialize-array';
import {
  RoastpicSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { TableColumnDefinitionInput } from 'fabscale-app/models/table-column-definition';
import { DateFormat } from 'fabscale-app/utilities/utils/format-date';
import CellId from 'fabscale-app/components/page/roast-pic/table/cell/id';
import CellCoffeeType from 'fabscale-app/components/page/roast-pic/table/cell/coffee-type';
import CellCoffeeSize from 'fabscale-app/components/page/roast-pic/table/cell/coffee-size';
import CellCoffeeColor from 'fabscale-app/components/page/roast-pic/table/cell/coffee-color';
import CellCoffeeDefect from 'fabscale-app/components/page/roast-pic/table/cell/coffee-defect';

const DEFAULT_SORT_BY = 'ID';
const DEFAULT_SORT_DIRECTION = 'ASC';

interface Args {
  page?: number;
  pageSize?: number;
  sortBy?: RoastpicSortOption;
  sortDirection?: SortDirection;
  updatePage: (page?: number) => void;
  updatePageSize: (pageSize?: number) => void;
  updateSortBy: (sortBy?: RoastpicSortOption) => void;
  updateSortDirection: (sortDirection?: SortDirection) => void;
}

export default class PageRoastPicTable extends Component<Args> {
  @service l10n: L10nService;
  @service router: RouterService;
  @tracked selectedItems = new Set();
  @service('store/lab-sample') labSamplesStore!: LabSampleStore;

  @tracked data: PaginatedLabSamples = {
    items: [],
    pageInfo: { page: 0, pageSize: 0, totalPages: 0, totalItemCount: 0 },
  };

  @tracked isLoading = true;
  @tracked isError = false;
  @tracked currentURL = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.currentURL = this.router.currentURL;
    this.router.on('routeDidChange', this.updateCurrentURL);
    this.loadDataTask.perform();
  }

  willDestroy() {
    super.willDestroy();
    this.router.off('routeDidChange', this.updateCurrentURL);
  }

  @action
  updateCurrentURL() {
    this.currentURL = this.router.currentURL;
    this.loadDataTask.perform();
  }

  get queryParams() {
    let currentURL = this.currentURL;
    let queryString = currentURL.split('?')[1] || '';
    let params = new URLSearchParams(queryString);
    let queryParams: { [key: string]: string } = {};
    for (let [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    return queryParams;
  }

  get filters(): any {
    const { dateRange, coffeeTypes } = this.queryParams;

    const parsedDateRange = dateRange
      ? deserializeDateRange(dateRange as string)
      : undefined;
    const parsedCoffeeTypes = coffeeTypes
      ? deserializeArray(coffeeTypes as string)
      : undefined;

    return {
      dateRange: parsedDateRange,
      coffeeTypes: parsedCoffeeTypes,
    };
  }

  @cached
  get filtersKey() {
    return JSON.stringify(this.filters);
  }

  loadDataTask = restartableTask(async () => {
    try {
      this.isLoading = true;

      let filters = this.filters;
      let page = this.page;
      let pageSize = this.pageSize;
      let sortBy = this.sortBy;
      let sortDirection = this.sortDirection;

      this.data = await this.labSamplesStore.getLabSamplesPaginated(
        filters,
        { page, pageSize },
        { sortBy, sortDirection }
      );
      this.isError = false;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  });

  get page() {
    return this.args.page || 1;
  }

  get pageSize() {
    return this.args.pageSize || 25;
  }

  get sortBy() {
    return this.args.sortBy || DEFAULT_SORT_BY;
  }

  get sortDirection() {
    return this.args.sortDirection || DEFAULT_SORT_DIRECTION;
  }

  get columns(): TableColumnDefinitionInput[] {
    let { l10n } = this;

    let cols: any[] = [
      {
        title: '',
        propertyName: '',
        cellType: 'TOGGLE_CHECKBOX',
        tdClass: 'print-hide',
        thClass: 'print-hide',
      },
      {
        propertyName: 'id',
        title: l10n.t('ID'),
        component: CellId,
        thClass: 'nowrap',
        tdClass: 'nowrap',
        noCompactTitle: true,
        sortBy: 'ID',
      },
      {
        propertyName: 'roastBatch.startDate',
        title: l10n.t('Batch timestamp'),
        sortBy: 'CREATED_DATE',
        cellType: 'DATE',
        cellData: {
          dateFormat: DateFormat.DateTime,
        },
      },
      {
        propertyName: 'roastBatch.plantAsset.name',
        title: l10n.t('Roaster'),
        sortBy: 'ROAST_BATCH_NUMBER',
        cellType: 'DEFAULT',
      },
      {
        propertyName: 'lotNumber',
        title: l10n.t('Lot number'),
        sortBy: 'LOT_NUMBER',
        cellType: 'DEFAULT',
      },
      {
        propertyName: 'coffeeType',
        title: l10n.t('Coffee type'),
        sortBy: 'COFFEE_TYPE',
        component: CellCoffeeType,
      },
      {
        propertyName: 'coffeeVariety',
        title: l10n.t('Coffee variety'),
        sortBy: 'COFFEE_VARIETY',
        cellType: 'DEFAULT',
      },
      {
        propertyName: 'coffeeSize',
        title: l10n.t('Size'),
        thClass: 'nowrap',
        tdClass: 'nowrap',
        sortBy: 'COFFEE_SIZE',
        component: CellCoffeeSize,
      },
      {
        propertyName: 'coffeeColor',
        title: l10n.t('Color'),
        thClass: 'nowrap',
        tdClass: 'nowrap',
        sortBy: 'COFFEE_COLOR',
        component: CellCoffeeColor,
      },
      {
        propertyName: 'numberDefectsCatOne',
        title: l10n.t('Defect'),
        sortBy: 'NUMBER_DEFECTS',
        thClass: 'nowrap',
        tdClass: 'nowrap',
        component: CellCoffeeDefect,
      },
    ];

    return cols;
  }

  @action
  updateSort(sortBy: RoastpicSortOption, sortDirection: SortDirection) {
    if (sortBy !== this.args.sortBy) {
      this.args.updateSortBy(sortBy);
      this.loadDataTask.perform();
    }

    if (sortDirection !== this.args.sortDirection) {
      this.args.updateSortDirection(sortDirection);
      this.loadDataTask.perform();
    }
  }

  @cached
  get selectedRecords() {
    return this.data.items.filter((item: any) =>
      this.selectedItems.has(item.id)
    );
  }

  @cached
  get isLimitReached() {
    return this.selectedItems.size >= 2;
  }

  @action
  toggleRecord(record: any) {
    if (this.selectedItems.has(record.id)) {
      this.selectedItems.delete(record.id);
    } else if (!this.isLimitReached) {
      this.selectedItems.add(record.id);
    }
    this.selectedItems = new Set([...this.selectedItems]);
  }

  @action
  shouldDisableCheckbox(recordId: number) {
    return this.selectedItems.size >= 2 && !this.selectedItems.has(recordId);
  }

  @action
  navigateBasedOnSelection() {
    let selectedIds = Array.from(this.selectedItems);

    if (selectedIds.length === 1) {
      this.router.transitionTo('routes/roast-pic.show', Number(selectedIds[0]));
    } else if (selectedIds.length === 2) {
      this.router.transitionTo(
        'routes/roast-pic.compare',
        Number(selectedIds[0]),
        Number(selectedIds[1])
      );
    }
  }
}
